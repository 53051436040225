import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card, Segment, Grid, Icon } from "semantic-ui-react"
import GoldenMilkImg from "../images/4.jpeg"
import GoldenMilkImg2 from "../images/5.jpeg"
import GoldenMilkImg3 from "../images/6.jpeg"

const SinglePostPage = () => {
  return (
    <Layout>
      <SEO title="Easy Green Salad | My Food Book " />

      <div style={{ margin: "100px 0" }}>
        <Card
          className="single-post-page-card"
          style={{ width: "90vw", maxWidth: "900px" }}
          image={GoldenMilkImg2}
          centered
          header={<h1 style={{ textAlign: "center" }}>Easy Green Salad</h1>}
          meta={
            <div>
              <Segment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "grey",
                  }}
                >
                  <div>
                    By : <b>Nibedita Satpathy</b>
                  </div>
                  <div>
                    Published On: <b>05 May, 2020</b>
                  </div>
                </div>
              </Segment>
              <Segment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "grey",
                  }}
                >
                  <div>
                    Time to prepare : <b>20 Min</b>
                  </div>
                  <div>
                    Servings : <b> 2 Persons</b>
                  </div>
                </div>
              </Segment>
              <br />
            </div>
          }
          description={
            <div>
              <Segment>
                <h2>Ingrediants</h2>
                <div>
                  1. 1 cup Baby spinach
                  <br />
                  2. 1 cup Lettuce
                  <br />
                  3. few cherry tomatoes
                  <br />
                  4. Few Olives
                  <br />
                  5. 1 ripe Avocado
                  <br />
                  6. 2-3 small Radish
                  <br />
                  7. 1/4 thinly sliced red Onion
                  <br />
                  8. 1/2 cup croutons
                  <br />
                  9. 1 tbsp parmeasan <br />
                  <br />
                  <strong>for Dressing:</strong>
                  <br /> <br />
                  1. 1 tsp extra virgin Oilve oil
                  <br />
                  2. 1/3 tsp salt ,to taste
                  <br />
                  3. 1 tsp Lemon juice
                  <br />
                  4. 1/4 tsp crushed pepper,to taste
                  <br />
                </div>
              </Segment>
              <Segment style={{ fontSize: "18px" }}>
                <h2>Procedure</h2>
                <p>
                  1. In a cup ,mix all the ingredients for dressing .adjust the
                  salt to tatse.
                  <br />
                  2. In a mixing bowl combine all the ingredients for salad.
                  <br />
                  3. Drizzle the salad with just enough dressing and give it a
                  gentle toss to mix all of the ingredients and flavour.Serve
                  immedietly with little parmesean on top.
                </p>
              </Segment>
              <Segment>
                <h2>Dish Gallery</h2>
                <Grid
                  columns={3}
                  centered
                  padded="vertically"
                  celled="internally"
                  stackable
                >
                  <Grid.Row>
                    <Grid.Column fluid>
                      <img
                        src={GoldenMilkImg}
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src={GoldenMilkImg2}
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src={GoldenMilkImg3}
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment>
                <h2>Share</h2>
                <div>
                  <Icon
                    circular
                    size="large"
                    color="blue"
                    name="facebook square"
                  />
                  <Icon circular size="large" color="violet" name="linkedin" />
                  <Icon circular size="large" color="teal" name="twitter" />
                  <Icon circular size="large" color="green" name="whatsapp" />
                </div>
              </Segment>
            </div>
          }
        />
      </div>
    </Layout>
  )
}

export default SinglePostPage
